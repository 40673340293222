import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import React from "react";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import { useAppSelector } from "src/store/store";
import { removeSpecialCharactersFromString } from "src/utils/helpers";

const placementSelections = () => {
  const { placementDetails } = useAppSelector(
    (state) => state.placementDetailsPage
  );

  return (
    <Table
      headerRows={1}
      rowComponents={[UncontrolledExpandableRow, TableRow]}
      showStripes={true}
      showDividers={true}
    >
      <TableRow>
        <TableCell>Job(s)</TableCell>
        <TableCell>Shift</TableCell>
        <TableCell>Job Type</TableCell>
        <TableCell>Hours Assigned</TableCell>
      </TableRow>

      {placementDetails.jobsDisplayed && placementDetails?.allocatedJobShifts?.map((jobShift, index) => (
        <TableRow key={index} data-cy="placement-details__job-row">
          <TableCell
            data-cy={`placement-details__placements__job-${removeSpecialCharactersFromString(
              jobShift.jobName
            )}`}
          >
            {jobShift.jobName} {placementDetails.jobSuffix}
          </TableCell>
          <TableCell>{jobShift.shiftCode}</TableCell>
          <TableCell>{jobShift.jobType}</TableCell>
          <TableCell
            data-cy={`placement-details__placements__hours-${removeSpecialCharactersFromString(
              jobShift.jobName
            )}`}
          >
            {jobShift.allocatedHours}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default placementSelections;
