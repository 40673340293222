import React from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import { useAppSelector } from "src/store/store";
import { setIsProviderPlacedOffWorkModalOpen, setAccommodationSavingStatus } from "src/store/newJmrPageSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApiCallStatus } from "src/store/type";
import { PLACEMENT_DETAILS_BASEURL } from "src/constants/Routes";

const ProviderPlacedOffWorkModal = () => {
  const dispatch = useDispatch();
  const { isProviderPlacedOffWorkModalOpen, createdAccommodationId } = useAppSelector(
    (state) => state.newJmrPage
  );
  const navigate = useNavigate();
  const onClose = () => {
    dispatch(setIsProviderPlacedOffWorkModalOpen(false));
    navigate(`${PLACEMENT_DETAILS_BASEURL}/${createdAccommodationId}`)
    dispatch(setAccommodationSavingStatus(ApiCallStatus.Idle))
  };

  return (
    <React.Fragment>
      <Modal
        title="Provider Placed Off Work"
        open={isProviderPlacedOffWorkModalOpen}
        width="350px"
        scrollContainer="viewport"
        closeLabel="Close"
      >
        <Text id="modal-description">{`Associate's status has been updated to "Provider Placed Off Work", You will now be directed to the placement details page for this associate.`}</Text>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button data-cy="provider-placed-off-work__ok-button" type="primary" size="small" onClick={onClose}>
              OK
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ProviderPlacedOffWorkModal;
