/**
 * convert the string that the Meridian DatePicker component returns into
 * a javascript Date object
 * @param dateStr the date string in format YYYY-MM-DD
 */
export const datePickerStringToDateObj = (dateStr: string) => {
  // dateStr is empty when going through the "Alternate Placements" flow, when the 
  // placement's end date prior to this flow was 3000-02-02. This only occurs for
  // sites not part of the DH pilot.
  const INDEFINITE_RESTRICTIONS_DATE = "3000-02-02";
  dateStr = dateStr === "" ? INDEFINITE_RESTRICTIONS_DATE : dateStr;
  const spiltString = dateStr?.split("-");
  // the months start from '0', so we have to subtract 1
  if (spiltString) {
    return new Date(
      +spiltString[0],
      +spiltString[1] - 1,
      +spiltString[2].split("T")[0]
    );
  }
};
